#tldr {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

#left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
}

#right {
  margin-left: 20px;
  flex: 1.75;
}

#avatar img {
  max-width: 20vw;
  border-radius: 50%;
  border: 4px solid #eee;
}

#name {
  font-size: 6vw;
  font-weight: 600;
  margin-bottom: 10px;
}

#info {
  text-align: left;
}

#about {
}

@media only screen and (min-width: 768px) {
  #avatar img {
    max-width: 200px;
  }

  #info {
    width: 200px;
    margin: 0 auto 0 40px;
  }

  #name {
    font-size: 4.5em;
  }
}
