@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";
@import "~@blueprintjs/select/lib/css/blueprint-select.css";

html,
body {
  width: 100%;
  height: 100%;
}

body,
button {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1em;
  color: #565354;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 100;
  margin-top: 1vw;
}

img {
  max-width: 100%;
  max-height: 100%;
}

textarea {
  resize: vertical;
}

b {
  font-weight: 600;
}

#signed-in-as {
  display: none;
}

#root {
  display: grid;
  min-height: 100%;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
}

#default {
  /*width: 96vw;*/
  /*margin: 0 auto;*/
  padding: 2vw;
}

header .bp3-button {
  font-size: 3.2vmin;
}

#landing {
  width: 94vw;
  margin: 0 auto;
}

.m-1 {
  margin: 5px;
}

.m-2 {
  margin: 10px;
}

.inline {
  display: inline-block !important;
}

.m-y-2 {
  margin: 10px 0 10px 0;
}

.m-x-2 {
  margin: 0 10px 0 10px;
}

.p-1 {
  padding: 5px;
}

.p-2 {
  padding: 10px;
}

.error {
  color: #c23030;
}

/* This is a FIX for a rendering issue in BlueprintJS DatePicker */
.bp3-html-select.bp3-minimal select {
  font-size: 80%;
}

.bp3-menu {
  max-height: 300px;
  overflow: auto;
}

/* END fix */

.bp3-callout {
  margin-bottom: 10px;
}

/* Start github badge CSS */
.github-corner:hover .octo-arm {
  animation: octocat-wave 560ms ease-in-out;
}

.github-corner {
  display: none;
}
/* End github badge CSS */

/* Bug fix for iOS Safari changing it's default stylesheet */
.bp3-input {
  -webkit-appearance: none;
}


@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}

.explain {
  margin-bottom: 10px;
  font-size: 90%;
}

.main-text {
  width: 90vw;
}

.module {
  border: 2px solid #eee;
  border-radius: 3px;
  margin-bottom: 1vw;
  padding: 10px;
}

@media (max-width: 500px) {
  .github-corner:hover .octo-arm {
    animation: none;
  }
  .github-corner .octo-arm {
    animation: octocat-wave 560ms ease-in-out;
  }
}

/* End github badge CSS */

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4 {
  font-size: 110% !important;
}

@media only screen and (min-width: 768px) {
  header .bp3-button {
    font-size: 14px;
  }

  #landing {
    width: 60vw;
    margin: 0 auto;
  }

  .main-text {
    width: 60vw;
  }

  .non-ideal-content {
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }

  #signed-in-as {
    display: inline;
  }

  .github-corner {
    display: inline;
  }
}
