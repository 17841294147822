#widget {
  position: relative;
  padding-bottom: '56.25%' /* 16:9 */;
  padding-top: 25;
  height: 0;
}

#widget iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
